section h3.section-title {
	background-color: $brand-primary;
	color: white;
	margin-left: -30px;
	margin-right: -30px;
	margin-bottom: 25px;
	padding: 8px 15px;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.15em;
}

section.car-info {
	margin-bottom: $grid-gutter-width;
	.car-name {
		text-align: center;
		margin-top: 15px;
		margin-bottom: 15px;
		h1 {
			margin-top: 5px;
		}
		h3 {
			margin-bottom: 0;
			@extend .serif-gray;
		}
	}
	.car-thumbnails {
		text-align: center;
		margin-bottom: 30px;
		li {
			padding: 0;
			a {
				max-width: 130px;
				display: block;
				overflow: hidden;
				margin-bottom: 5px;
				margin-left: 5px;
				margin-right: 5px;
				position: relative;
				
				img {
					transition: transform 0.3s ease-out;
				}
				&:hover img, &:hover::before {
					transform: scale(1.07);
					background-color: rgba(black, 0);
				}
				&::before {
					position: absolute;
					content: " ";
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
					background-color: rgba(black, 0.2);
					transition: background 0.3s ease-out;
					z-index: 2;
				}
			}
		}
	}
	.car-stats {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 30px;
		.car-power, .car-year, .car-price {			
			padding: 10px;
			font-weight: 300;
			font-size: 27px;
			max-width: 300px;
			margin: 0 auto;
			
			@media (min-width:$screen-md-min) {
				width: 33%;
				float: left;
				padding: 15px;
				max-width: none;
			}
			
			span {
				@extend .serif-gray;
				display: block;
				text-transform: uppercase;
				font-size: 15px;
				font-style: normal;
			}
		}
		.car-year {
			border-top: 1px solid $brand-primary;
			border-bottom: 1px solid $brand-primary;
			@media (min-width:$screen-md-min) {
				border: none;
				border-left: 1px solid $brand-primary;
				border-right: 1px solid $brand-primary;
			}
		}
	}
}

section.car-details {
	.car-key-data {
		list-style: square;
		padding-left: 1em;
	}
}

section.car-request {
	.car-request-form form {
		@media (min-width:$screen-sm-min) {
			margin-right: 45px;
		}
	}
}

