@keyframes fade-in-up-sm {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-up-sm {
  animation-name: fade-in-up-sm;
}


@keyframes fade-out-up-sm {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
}


.fade-out-up-sm {
  animation-name: fade-out-up-sm;
}
