header.header {
	/*background-color: white;
	.logo {
		max-width: 270px;
		margin: 35px auto 20px;
		@media (min-width:$screen-sm-min) {
			margin: 65px auto 40px;
		}
	}*/
}

.slider-container {
	position: relative;

	.bxslider {
		visibility: hidden;
		img {
			width: 100%;
			max-width: none;
		}
	}
	#bx-controls {
		a {
			color: white;
			font-size: 28px;
			padding: 15px;
			opacity: 0.7;
			transition: all 1s ease;
			&:hover {
				opacity: 1;
			}
		}
		.slider-next, .slider-prev {
			position: absolute;
			top: calc(50% - 30px);
		}
		.slider-next {
			right: 0;
		}
		.slider-prev {
			left: 0;
		}
	}
	.bxslider {
		li {
			opacity: 0;
			height: 40vh;
			background-size: cover;
			background-position: center center;
			.single-cars_post_type & {
				background-position: center 70%;
			}

			&.lazyloaded {
				opacity:0;
				animation:fadeInBG ease-in;
				animation-fill-mode:forwards;
				animation-duration:0.3s;
			}
			@media (min-width:$grid-float-breakpoint) {
				height: 80vh;
				//min-height: 450px;
				//max-height: 1000px;
			}
			@media (min-width:$grid-float-breakpoint) and (max-aspect-ratio: 4/3) {
				height: 57vh;
			}
		}
	}

	// Lazyloading
	/*.bxslider li img {
		&.lazyload, &.lazyloading {
			opacity: 0;
		}
		&.lazyloaded {
			opacity:0;
			animation:lazyFade ease-in;
			animation-fill-mode:forwards;
			animation-duration:0.4s;

			@keyframes lazyFade {
				from { opacity:0; }
				to { opacity:1; }
			}
		}
	}*/
}
