body.home {
	main {
		padding-bottom: 0;
		padding-top: 0;
	}
	footer, header {
		display: none;		
		visibility: hidden;
	}
}
.frontpage-section {
	min-height: 750px;
	height: 100vh;
	background-size: cover;
	background-position: center center;
	//background-image: url(../img/frontpage_slide.jpg);
	background-color: #ddd;
	position: relative;
	color: darken(white, 5%);
	
	&::after {
		position: absolute;
		content: " ";
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: linear-gradient(to bottom, rgba(34,34,34,0.0) 0%, rgba(34,34,34,0.0) 62%, rgba(34,34,34,0.35) 70%,rgba(34,34,34,1) 100%);
		z-index: 1;
		opacity: 0;
		animation: frontpage-fade 1s ease-out 0s forwards;
	}
	@keyframes frontpage-fade {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	.frontpage-intro {
		position: absolute;
		z-index: 2;
		padding: 25px;
		bottom: 15px;
		max-width: 500px;
		@media (min-width:$screen-sm-min) {
			right: 10%;
			bottom: 4%;
		}
		@media (min-width:$screen-md-min) {
			max-width: 600px;
			opacity: 0;
			animation: fade-in-up-sm 0.7s ease-out 1.3s forwards;
		}
	}
	.frontpage-logo {
		position: absolute;
		left: 50%;
		top: 25px;
		width: 200px;
		transform: translateX(-50%);
		opacity: 0.8;
		z-index: 99;
		@media (min-width:$screen-sm-min) {
			top: 7%;
			left: 10%;
			transform: none;
			opacity: 0;
			animation: fade-in-up-sm 0.7s ease-out 1s forwards;
		}
		@media (min-width:$screen-lg-min) {
			width: 240px;
			left: 15%;				
		}
	}
	h1 {
		margin-top: 0;
	}
	nav > ul {
		text-transform: uppercase;
		font-family: 'Roboto Condensed';
		font-size: 15px;
		letter-spacing: 0.2em;
		font-weight: 700;
		padding-bottom: 5px;
		//text-shadow: 0px 2px 15px rgba(black, 0.7);
		li {
			display: block;
			@media (min-width:$screen-sm-min) {
				display: inline-block;
				//text-shadow: 0px 2px 15px rgba(black, 0.4);
			}
		}		
	}
	nav.frontpage-mainnav > ul {
		background-color: rgba(white, 0.75);
		border-left: 15px solid rgba(black, 0.8);
		padding-top: 5px;
//		padding-bottom: 0;
		padding-left: 10px;
		padding-right: 15px;
		margin-left: 0;
		
		// language switcher
		#qtranslate-chooser {
			display: inline-block;
			padding-left: 5px;
			font-weight: 400;
			font-size: 12px;
			list-style: none;
			
			li {
				padding: 0 4px;
				&.active {
					font-weight: 700;	
				}
			}
		}
	}
	nav.frontpage-subnav ul {
		font-size: 11px;
		margin-top: 10px;
		a {
			color: darken(white, 5%);
		}
	}
	// Slider
	.bxslider {
		position: absolute;
		background-size: cover;
		background-position: center center;	
		background-image: url(../img/frontpage_bg.jpg);
		height: 100vh;
		min-height: 750px;
		li {
			height: 100vh;
			min-height: 750px;
			width: 100%;
			opacity: 0;
			background-size: cover;
			background-position: center center;	
			&.lazyloaded {
				opacity:0;
				animation:fadeInBG ease-in;
				animation-fill-mode:forwards;
				animation-duration:0.3s;
				@keyframes fadeInBG { 
					from { opacity:0; } 
					to { opacity:1; } 
				}
			}
		}
	}
}