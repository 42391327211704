.current-cars {
	clear: both;
	display: block;
	.car-item-title, .car-item-closeup {
		height: 200px;		
		position: relative;
		overflow: hidden;
		@media (min-width:$screen-sm-min) {
			height: 300px;
		}
		@media (min-width:$screen-md-min) {
			height: 350px;
		}
		
		&::after {
			content: " ";
			background-color: rgba(black, 0.35);
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			position: absolute;
			z-index: 3;
			transition: all 0.5s ease-out;
		}
		.car-item-bg, .car-item-closeup-bg {
			background-size: cover;
			background-position: center center;
			display: table;
			width: 100%;
			height: 100%;
		}
	}
	header.title-header {
		vertical-align: middle;
		display: table-cell;
		position: relative;
		z-index: 5;
		padding:0 20px;
		color: white;
		
		h1, h3 {
			margin-top: 0;
			margin-bottom: 3px;
			transition: all 0.3s ease-out;
			position: relative;
			text-shadow: 0px 0px 15px black;
			color: white;
		}
		
	}
	.car-item {
		margin-bottom: 5px;
		@extend %clearfix;
		
		@media (min-width:$screen-sm-min) {
			.car-item-title {
				width: calc(70% - 5px);
				float: left;
				margin-right: 5px;
			}
			.car-item-closeup {
				width: 30%;
				float: left;
			}
			&:nth-child(even) {
				.car-item-title {
					float: right;
					margin-right: 0;
					margin-left: 5px;
				}
			}
		}
	}
	
	// Hover effects
	.car-item a {		
		&:hover {
			color: inherit;
			text-decoration: none;
			.car-item-title::after, .car-item-closeup::after {
				background-color: rgba(black, 0.05);
			}
			header.title-header h1 {
				transform: translateY(5px);
			}
			header.title-header h3 {
				transform: translateY(-5px);
			}
		}
	}
}