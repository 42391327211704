$h1-font-sizes: (
  null  : floor($font-size-h1 * 0.8),
  $screen-sm-min : floor($font-size-h1 * 0.85),
  $screen-md-min : floor($font-size-h1 * 0.9),
  $screen-lg-min:  floor($font-size-h1)
);

$h2-font-sizes: (
  null  : floor($font-size-h2 * 0.8),
  $screen-sm-min : floor($font-size-h2 * 0.85),
  $screen-md-min : floor($font-size-h2 * 0.9),
  $screen-lg-min:  floor($font-size-h2)
);

$h3-font-sizes: (
  null  : ceil($font-size-h3 * 0.8),
  $screen-sm-min : ceil($font-size-h3 * 0.85),
  $screen-md-min : ceil($font-size-h3 * 0.9),
  $screen-lg-min:  ceil($font-size-h3)
);

$article-padding: (
	null: 30px 0,
	$screen-sm-min: 35px 0,
	$screen-md-min: 40px 0,
	$screen-lg-min: 50px 0,
);

@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    }
    @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

@mixin article-padding-size($fs-map) {
  @each $fs-breakpoint, $fs-article-padding in $fs-map {
    @if $fs-breakpoint == null {
      padding: $fs-article-padding;
    }
    @else {
      @media screen and (min-width: $fs-breakpoint) {
        padding: $fs-article-padding;
      }
    }
  }
}

%clearfix {
  *zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin antialiased {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
}

$custom-navbar-height: 60px;
