nav.top-nav {
	text-transform: uppercase;
	font-family: 'Roboto Condensed';
	font-size: 15px;
	letter-spacing: 0.15em;
	font-weight: 700;
	//text-align: center;
	
	> ul {
		margin-bottom: 0;
		background: rgba(white, 0.85);
		height: $custom-navbar-height;
		padding-top: 16px;
		margin-left: 29%;
		
		// language switcher
		#qtranslate-chooser {
			display: inline-block;
			padding-left: 5px;
			font-weight: 400;
			font-size: 12px;
			list-style: none;
			> li {
				display: inline-block;
				padding-left: 5px;
				padding-right: 5px;
				
				&.active {
					font-weight: 700;	
				}

			}
		}
	}
	
	/*ul {
		margin: 15px auto;
		max-width: 800px;
		 @extend %clearfix;
		li {
			float: left;
			padding-left: 25px;
			padding-right: 25px;
			&:nth-child(3),&:nth-child(4) {
				float: right;
			} 
		}
	}*/
}

// Menu Icon
.menu-icon {
	display: block;
	height: 28px;
	width: 35px;
	//margin-left: -20px;
	margin-bottom: 30px;
	position: relative;
	float: left;
	cursor: pointer;
	z-index: 999;
	left: 25px;
	top: 15px;

	&:hover .box-shadow-menu:before {
		background: white;
		box-shadow: 0 8px 0 0 white, 0 16px 0 0 white;
	}
}
.box-shadow-menu {
	position: relative;
	left: 0;

	&:before {
		content: "";
		position: absolute;
		top: 2px;
		left: 0;
		width: 35px;
		height: 3px;
		background: white;
		z-index: 999;
		box-shadow: 0 8px 0 0 white, 0 16px 0 0 white;
	}	
}

// Pushy language switcher
.pushy #qtranslate-chooser {
	list-style: none;
	padding: 0;
	padding-left: 10px;
	li {
		display: inline-block;
		width: auto;
		a {
			padding: 10px 20px;
			
		}
	}
}

// Neue Nav
header.header {
	position: relative;
	z-index: 99;
	width: 100%;
	top: 0;
	@media (min-width:$grid-float-breakpoint) {
		position: absolute;
	}
	.logo {
		background-color: $brand-primary;
		padding: 0px 30px;
		padding-top: 12px;
		height: $custom-navbar-height;
		margin-right: 30px;
		width: 100%;
		text-align: center;
		img {
			width: 180px;
		}
		@media (min-width:$grid-float-breakpoint) {
			width: 29%;	
			text-align: right;
			float: left;
			background-color: rgba(black, 0.8);
		}
	}
}