footer {
	background-color: $brand-primary;
	color: darken(white, 15%);
	font-size: 13px;
	padding-top: 20px;
	padding-bottom: 20px;
	
	a {
		color: inherit;
		&:hover {
			color: white;
			text-decoration: none;
		}
	}
	
	.footer-logo, .footer-info, .footer-nav {
		margin-bottom: 20px;
		text-align: center;		
	}
	.footer-logo {		
		img {
			max-width: 150px;
			opacity: .85;
		}
	}
	.footer-info {
		text-align: center;
	}
	
	.footer-nav {
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}
	
	
	@media (min-width:$screen-sm-min) {
		padding-top: 50px;
		padding-bottom: 50px;
		
		.footer-logo, .footer-info, .footer-nav {
			margin-bottom: 0;
		}
	}
	
}