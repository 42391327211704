img {
	max-width: 100%;
	height: auto;
}

h1, h2 {
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 400;
}


h1 {
	@include font-size($h1-font-sizes);
}
h2 {
	@include font-size($h2-font-sizes);
}
h3 {
	@include font-size($h3-font-sizes);
}

a:focus, a:active {
	outline: none;
}

.serif-gray {
	font-family: 'PT Serif';
	color: lighten($text-color, 20%);
	letter-spacing: 0.2em;
	@include antialiased;
	font-weight: 400;
	font-style: italic;
	text-transform: uppercase;
}

.container-fluid {
	max-width: 1200px;
}

.site-container {
	background-color: white;
}
.animsition {
	//background-color: #eee;
}

form {
	margin-top: 0.7em;
	margin-bottom: 1.3em;
	
	label {
		display: none;
		visibility: hidden;
	}
}

	
.form-control {
	border-top: none;
	border-left: none;
	border-right: none;
	box-shadow: none;	
	
	&:focus {
		box-shadow: none;
	}
}

textarea.form-control{
	border: 1px solid $input-border;
}

main {
	background-color: white;
	padding-top: 25px;
	padding-bottom: 25px;
	&.container-fluid {
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		@media (min-width:$screen-md-min) {
			padding-left: $grid-gutter-width * 2;
			padding-right: $grid-gutter-width * 2;
		}
		@media (min-width:$screen-lg-min) {
			padding-left: $grid-gutter-width * 3;
			padding-right: $grid-gutter-width * 3;
		}
	}
	&.no-slider {
		margin-top: 25px;
		@media (min-width:$grid-float-breakpoint) {
			margin-top: ($custom-navbar-height + 25px);
		}
	}
	
	header.title-header {
		text-align: center;
		@include article-padding-size($article-padding);
		h1 {
			margin-top: 0;
			margin-bottom: 0;
		}
		h3 {
			margin-top: 5px;
			@extend .serif-gray;
		}
	}
}


