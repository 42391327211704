.gallery-cars {
	clear: both;
	display: block;
	background: $brand-primary;
	@extend %clearfix;
	.car-item-title {
		height: 250px;		
		position: relative;
		overflow: hidden;
		@media (min-width:$screen-sm-min) {
			height: 280px;
		}
		@media (min-width:$screen-md-min) {
			height: 300px;
		}
		
		&::after {
			content: " ";
			//background-color: rgba(black, 0.35);
			background: linear-gradient(to bottom, rgba(34,34,34,0.20) 0%,rgba(34,34,34,0.25) 75%,rgba(34,34,34,1) 100%);
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			position: absolute;
			z-index: 3;
			transition: all 0.5s ease-out;
		}
		.car-item-bg {
			background-size: cover;
			background-position: center center;
			display: table;
			width: 100%;
			height: 100%;
		}
	}
	header.title-header {
		vertical-align: middle;
		display: table-cell;
		position: relative;
		z-index: 5;
		padding:0 20px;
		color: white;
		
		h1, h3 {
			margin-top: 0;
			margin-bottom: 3px;
			transition: all 0.3s ease-out;
			position: relative;
			text-shadow: 0px 0px 15px black;
			color: white;
		}
		
	}
	
	.car-item-descr {
		background: $brand-primary;
		color: darken(white, 15%);
		padding: 25px;
		font-size: ceil(($font-size-base * 0.85))
	}
	
	.car-item {
		//margin-bottom: 5px;
		border-bottom: 5px solid white;
		@extend %clearfix;
		text-align: center;
		@media (min-width:$screen-sm-min) {
//			width: calc(50% - 5px);
//			margin-right: 5px;
			width: 50%;
			border-right: 5px solid white;
			float: left;

			&:nth-child(2n) {
//				margin-right: 0;
				border-right: none;
				width: 50%;
			}	
			&:nth-last-child(2) {
				border-bottom: none;
			}
		}
		&:last-child {
			border-bottom: none;
		}
	}
	
	// Hover effects
	.car-item a {		
		&:hover {
			color: inherit;
			text-decoration: none;
			.car-item-title::after{
				background-color: rgba(black, 0.05);
			}
			header.title-header h1 {
				transform: translateY(5px);
			}
			header.title-header h3 {
				transform: translateY(-5px);
			}
		}
	}
}

// verkaufte autos liste
.sold-cars-list {
	background: $brand-primary;
	text-align: center;
	@extend %clearfix;
	clear: both;
	color: darken(white, 15%);
	margin-top: 5px;
	padding: 30px 15px;
	@media (min-width:$screen-sm-min) {
		padding: 35px;
	}
	
	h2 {
		margin-top: 0;
	}
	ul {
		list-style-type: none;
		margin-top: 25px;
		padding-left: 0;
		list-style-position: inside;
		@extend %clearfix;
	 	li {
			line-height: 1.4;
			padding-left: 5px;
			padding-right: 5px;
			margin-bottom: 10px;
			@media (min-width:$screen-sm-min) {
				width: 50%;
				float: left;
			}
			@media (min-width:$screen-md-min) {
				width: 33.3333%;
			}
			
		}
	}
}