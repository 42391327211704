 /* loading option */
.animsition-loading,
.animsition-loading:after {
  width: 32px;
  height: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 50%;
  z-index: 100;
}

.animsition-loading {
  background-color: transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
  border-right: 5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid $brand-primary;
  transform: translateZ(0);
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-duration: .8s;
  animation-name: animsition-loading;
}

@keyframes animsition-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
